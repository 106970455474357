/* FIX FOR MAP LOCATION PICKER*/
div.pac-container {
  z-index: 99999999 !important;
}


.popover {
  min-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
}

/* ------------------------------ */
/* DRAFTJS */
.wrapper-class {
  padding: 0;
  border: 0;
}

.editor-class {
  padding: 0.5rem;
  border: 1px solid #ccc;
  min-height: 150px;
}

.toolbar-class {
  background-color: rgba(0, 0, 0, 0.05) !important;
  border: 0 !important;
}

.wrapper-class .rdw-option-wrapper {
  background: transparent !important;
}

.wrapper-class .rdw-option-wrapper:hover {
  background: rgba(0, 0, 0, 0.08) !important;
}

.wrapper-class .rdw-option-active {
  background: rgba(0, 0, 0, 0.1) !important;
}
