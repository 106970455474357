/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 4.0.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";

// Custom DV SOFT
@import 'react-toastify/dist/ReactToastify.css';
.responsive-off-canvas {
  @media (max-width: 768px){
    width: 80% !important;
  }
  @media (min-width: 768px) {
    width: 75% !important;
  }
  @media (min-width: 992px) {
    width: 70% !important;
  }
  @media (min-width: 1400px) {
    width: 50% !important;
  }
}

.offcanvas-header {
  background-color: $primary;
  color: white;
}

.offcanvas-header .btn-close {
  background-color: $danger;
}

:root {
  --toastify-color-light: #{$light};
  --toastify-color-dark: #{$dark};
  --toastify-color-info: #{$info};
  --toastify-color-success: #{$success};
  --toastify-color-warning: #{$warning};
  --toastify-color-error: #{$danger};
}


.responsive-off-canvas .offcanvas-header {
  background-color: #{$primary} ;
  color: #{$white}
}

.required {
  &:after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: $danger;
    padding-left: 0.25rem;
    font-weight: bold;
  }
}

.cursor-pointer {
  cursor: pointer;
}


.btn-white {
  --#{$prefix}btn-color: #{$gray-600};
  --#{$prefix}btn-bg: #{$white};
  --#{$prefix}btn-border-color: #{$gray-400};
}

.btn-white {
  --#{$prefix}btn-hover-color: #{$gray-500};
  --#{$prefix}btn-hover-bg: #{$gray-100};
  --#{$prefix}btn-hover-border-color: #{$gray-400};
  --#{$prefix}btn-active-color: #{$gray-500};
  --#{$prefix}btn-active-bg: #{$gray-300};
  --#{$prefix}btn-active-border-color: #{$gray-400};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($gray-300)};
}
