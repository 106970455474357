//
// Range slider
//

.irs {
  font-family: $font-family-base;
}

.irs--square {
  cursor: pointer;

  .irs-bar,
  .irs-to,
  .irs-from,
  .irs-single {
    background: $primary !important;
    font-size: 11px;
  }

  .irs-to,
  .irs-from,
  .irs-single {
    &:before {
      border-top-color: $primary;
    }
  }

  .irs-line {
    background: var(--#{$prefix}gray-300);
    border-color: var(--#{$prefix}gray-300);
  }

  .irs-grid-text {
    font-size: 11px;
    color: var(--#{$prefix}gray-400);
  }

  .irs-min,
  .irs-max {
    color: var(--#{$prefix}gray-400);
    background: var(--#{$prefix}gray-300);
    font-size: 11px;
  }

  .irs-handle {
    border: 2px solid $primary;
    width: 12px;
    height: 12px;
    top: 26px;
    background-color: var(--#{$prefix}custom-white) !important;
    cursor: pointer;

    &:active {
      cursor: pointer;
    }
  }

  .irs-grid-pol {
    background-color: var(--#{$prefix}gray-400);
  }
}